import { http } from '../services/http'
import Cookie from 'js-cookie'
import Strings from '../common/strings'
export default{
    obtertemplates(){
        let option = {
            headers: {
              'Authorization': `Bearer ${Cookie.get(Strings.cookie_token)}`,
            }
        }
        return http.post('/Polichat/obter/templates', null, option)
    }
}