import { createApp } from 'vue'
import App from './App.vue'

import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'

import loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import { createWebHistory } from 'vue-router'
import createRouter from './route/router.js'

const app = createApp(App)

const router = createRouter(createWebHistory())

app.use(loading)

app.use(VueToast, {
    position: 'top'
})

app.use(router)

app.mount('#app')
