<template>
  <div class="container mt-4 p-5">
      <div class="row">
          <h3>Configurar integração (Moskit x Polichat)</h3>
      </div>
      <hr>
      <div class="row">
        <h4 class="p-1">Integração direta <i class="fas fa-slash"></i> Moskit <i class="fas fa-arrows-alt-h"></i> Polichat</h4>
      </div>
      <div class="row">
          <div class="col-sm-6">
            <div class="row">
                <p>Usuários</p>
            </div>
            <div class="row d-flex justify-content-center align-items-center">
                <div class="col-auto">
                    <button class="btn btn-rouded btn-control-sm btn-primary" data-mdb-toggle="modal" data-mdb-target="#mAssociarUsuarios">Associar</button>
                </div>
                <div class="col-auto">
                    <button class="btn btn-rouded btn-control-sm btn-primary" @click="listarAssociados()" data-mdb-toggle="modal" data-mdb-target="#mDesassociarUsuarios">Desassociar</button>
                </div>
            </div>
          </div>
          <div class="col-sm-6" style="border-left: 1px solid #ccc;">
            <div class="row">
                <p>Templates</p>
            </div>
            <div class="row d-flex justify-content-center align-items-center">
                <div class="col-auto">
                    <button @click="modalassociaretapastemplates()"
                        class="btn btn-rouded btn-control-sm btn-dark" data-mdb-toggle="modal" data-mdb-target="#metapatemplate">Associar Etapa / Template</button>
                </div>
                <div class="col-auto">
                    <button @click="listarEtapaTemplateAssociados()"
                        class="btn btn-rouded btn-control-sm btn-dark" data-mdb-toggle="modal" data-mdb-target="#mDesassociarEtapaTemplate">Remover associação</button>
                </div>
            </div>
          </div>
          <!--
          <div class="row">
              <p>Funis (Negócios)</p>
          </div>
          <div class="row d-flex justify-content-center align-items-center" data-mdb-toggle="modal" data-mdb-target="#mcadfunismoskit">
              <div class="col-auto">
                  <button class="btn btn-rouded btn-control-sm btn-success" @click="obterpipelines()">Funis</button>
              </div>
          </div>
          <hr>
          -->
        </div>
        <hr>
        <div class="row d-flex justify-content-start" style="text-align: left;">
            <div class="row">
                <div class="col-sm-6">
                    <h3 class="mt-2">Integração <i class="fas fa-slash"></i> RDStation <i class="fas fa-arrows-alt-h"></i> Polichat</h3>
                </div>
                <div class="col-sm-6 d-flex align-items-center">
                    <button @click="obterTemplatesPolichat()"
                        class="btn btn-rouded btn-control-sm btn-rounded btn-sm btn-success" data-mdb-toggle="modal" data-mdb-target="#polichatWebhookModal">Gerar webhook</button>
                </div>
            </div>
            <div class="row">
                <polichatWebhook :key="polichatwebhookkey"></polichatWebhook>
            </div>
        </div>
  </div>
    <!-- # Usuário # -->
    <!-- Modal Usuario associar -->
    <div class="modal top fade" id="mAssociarUsuarios" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-mdb-backdrop="true" data-mdb-keyboard="true">
    <div class="modal-dialog modal-lg ">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Associar usuários</h5>
            <button type="button" class="btn-close" data-mdb-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <label class="visually-hidden" for="inlineFormInputGroupUsername">Username</label>
                        <div class="input-group">
                        <div class="input-group-text">Id usuário polichat</div>
                            <input v-model="idpolichat" type="number" class="form-control" id="inlineFormInputGroupUsername" placeholder="" />
                        </div>
                    </div>
                    <div class="col-12 mt-3">
                        <div class="input-group">
                            <div class="input-group-text">Username Moskit</div>
                            <input v-model="this.username" type="text" class="form-control" id="inlineFormInputGroupUsername" placeholder="" />
                            <button @click="obterUsuarioMoskitPorUsername" type="button" class="btn btn-secondary">buscar</button>
                        </div>
                    </div>
                </div>
                <br>
                <div class="content">
                    <table class="table table-sm">
                    <thead>
                        <tr>
                        <th scope="col"></th>
                        <th scope="col">Id Moskit</th>
                        <th scope="col">Nome</th>
                        <th scope="col">E-mail</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(usersmoskit, index) in this.usersmoskit" v-bind:key="index">
                            <th scope="row">
                                <input class="form-check-input" v-bind:value="usersmoskit.id" type="radio" v-model="idmoskit" name="idMoskit" id="flexRadioDefault1"/>
                            </th>
                            <td>{{usersmoskit.id}}</td>
                            <td>{{usersmoskit.name}}</td>
                            <td>{{usersmoskit.username}}</td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-mdb-dismiss="modal">
            Fechar
            </button>
            <button type="button" data-mdb-dismiss="modal" class="btn btn-primary" @click="associarusuarioentreplataformas()">Associar</button>
        </div>
        </div>
    </div>
    </div>

    <!-- Modal Usuario desassociar -->
    <div class="modal top fade" id="mDesassociarUsuarios" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-mdb-backdrop="true" data-mdb-keyboard="true">
        <div class="modal-dialog modal-lg ">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Desassociar usuários</h5>
                <button type="button" class="btn-close" data-mdb-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="content">
                    <div class="row">
                        <p>Lista de usuários Moskit associados com usuários Polichat</p>
                    </div>
                    <table class="table table-sm">
                    <thead>
                        <tr>
                        <th scope="col"></th>
                        <th scope="col">Id Moskit</th>
                        <th scope="col">Id polichat</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(usersassociado, index) in this.usersassociados" v-bind:key="index">
                            <th scope="row">
                                <input v-model="radioselected" v-bind:value="usersassociado.id" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                            </th>
                            <td>{{usersassociado.idMoskit}}</td>
                            <td>{{usersassociado.idPolichat}}</td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-mdb-dismiss="modal">
                Fechar
                </button>
                <button type="button" data-mdb-dismiss="modal" class="btn btn-primary" @click="dessassociarUsuarios()">Desassociar</button>
            </div>
            </div>
        </div>
    </div>

    <!-- # Funis (Negócios) # -->
    <!-- Modal funis -->
    <div class="modal top fade" id="mcadfunismoskit" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-mdb-backdrop="true" data-mdb-keyboard="true">
        <div class="modal-dialog modal-lg ">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Lista funis Moskit</h5>
                <button type="button" class="btn-close" data-mdb-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div class="container">
                <div class="content">
                    <div class="row">
                        <p>Selecione os funis para monitoramento</p>
                    </div>
                    <table class="table table-sm">
                    <thead>
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">Nome</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(pipeline, index) in this.pipelines" v-bind:key="index">
                            <th scope="row">
                                <input v-model="pipeline.monitorado" class="form-check-input" type="checkbox" id="flexCheckDefault" />
                            </th>
                            <td style="text-align: start;">{{pipeline.name}}</td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                <div class="col-12 d-flex justify-content-center align-items-center">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination">
                            <li class="page-item">
                            <a @click="previouspipelines()" class="page-link" href="#" aria-label="Anterior">
                                <span aria-hidden="true">&laquo;</span>
                            </a>
                            </li>
                            <li class="page-item">
                            <a @click="nextpipelines()" class="page-link" href="#" aria-label="Proxima">
                                <span aria-hidden="true">&raquo;</span>
                            </a>
                            </li>
                        </ul>
                        </nav>
                </div>
            </div>
        </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-mdb-dismiss="modal">
                Fechar
                </button>
                <button type="button" data-mdb-dismiss="modal" class="btn btn-primary" @click="gravarfunil()">Salvar</button>
            </div>
            </div>
        </div>
    </div>

    <!-- # Templates # -->
    <!-- Modal etapa / template -->
    <div class="modal top fade" id="metapatemplate" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-mdb-backdrop="true" data-mdb-keyboard="true">
        <div class="modal-dialog modal-lg ">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Associe a etapa do Moskit com o template da Polichat</h5>
                <button type="button" class="btn-close" data-mdb-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="content">
                        <div class="row">
                            <p>Selecione a etapa</p>
                        </div>
                        <div class="d-flex justify-content-center">
                            <div>
                                <form class="form-inline">
                                    <div class="form-group mx-sm-3 mb-2">
                                        <select v-model="etapaSelected" class="form-control form-control-sm" data-mdb-original-title="" title="">
                                            <option v-for="(etapa, index) in this.etapas" v-bind:key="index" :value="{id: etapa.id, nome: etapa.pipeline.name + ' | ' + etapa.name}">
                                                {{etapa.pipeline.name}} | {{etapa.name}}
                                            </option>
                                        </select>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <p>Selecione o template</p>
                        </div>
                        <div class="d-flex justify-content-center">
                            <div>
                                <form class="form-inline">
                                    <div class="form-group mx-sm-3 mb-2">
                                        <select v-model="templateSelected" class="form-control form-control-sm" data-mdb-original-title="" title="">
                                            <option v-for="(template, index) in this.templates" v-bind:key="index" :value="{id: template.id, nome: template.tag}">{{template.tag}}</option>
                                        </select>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-mdb-dismiss="modal">
                Fechar
                </button>
                <button type="button" data-mdb-dismiss="modal" class="btn btn-primary" @click="associaretapatemplate()">Associar</button>
            </div>
            </div>
        </div>
    </div>

        <!-- Modal etapa / template desassociar -->
    <div class="modal top fade" id="mDesassociarEtapaTemplate" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-mdb-backdrop="true" data-mdb-keyboard="true">
        <div class="modal-dialog modal-lg ">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Desassociar Etapa / Template</h5>
                <button type="button" class="btn-close" data-mdb-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="content">
                    <table class="table table-sm">
                    <thead>
                        <tr>
                        <th scope="col"></th>
                        <th scope="col">Negócio | Etapa</th>
                        <th scope="col">Template</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(etapatemplate, index) in this.etapatemplateassociados" v-bind:key="index">
                            <th scope="row">
                                <input v-model="radioetapatemplate" :value="etapatemplate.idStagioMoskit" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                            </th>
                            <td>{{etapatemplate.nomeEtapaMoskit}}</td>
                            <td>{{etapatemplate.nomeTemplatePolichat}}</td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-mdb-dismiss="modal">
                Fechar
                </button>
                <button type="button" data-mdb-dismiss="modal" class="btn btn-primary" @click="desassociaretapatemplate()">Desassociar</button>
            </div>
            </div>
        </div>
    </div>

    <!-- Modal Gerar webhook polichat -->
    <div class="modal top fade" id="polichatWebhookModal" tabindex="-1" aria-labelledby="polichatWebhookModalLabel" aria-hidden="true" data-mdb-backdrop="true" data-mdb-keyboard="true">
        <div class="modal-dialog modal-sm ">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Gere Webhook para o template da Polichat</h5>
                <button type="button" class="btn-close" data-mdb-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="content">
                        <div class="row">
                            <p>Selecione o template</p>
                        </div>
                        <div class="d-flex justify-content-center">
                            <div>
                                <form class="form-inline">
                                    <div class="form-group mx-sm-3 mb-2">
                                        <select v-model="templateSelected" class="form-control form-control-sm" data-mdb-original-title="" title="">
                                            <option v-for="(template, index) in this.templates" v-bind:key="index" :value="{id: template.id, nome: template.tag}">{{template.tag}}</option>
                                        </select>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger btn-rounded btn-sm" data-mdb-dismiss="modal">
                Fechar
                </button>
                <button type="button" data-mdb-dismiss="modal" class="btn btn-primary btn-rounded btn-sm" @click="gerarWebHookPolichat()">Gerar</button>
            </div>
            </div>
        </div>
    </div>
</template>

<script>

import MoskitStore from '../store/MoskitStore.js'
import IntegracaoStore from '../store/IntegracaoStore.js'
import PolichatStore from '../store/PolichatStore.js'
import PolichatWebhook from './PolichatWebhook.vue'

export default {
    components: {
        PolichatWebhook,
    },
    data(){
        return {
            username: "",
            idmoskit: '',
            idpolichat: '',
            radioselected: '',
            radioetapatemplate: '',
            qtd: 10,
            start: 0,
            etapaSelected: '',
            etapaNome: '',
            templateSelected: '',
            templateNome: '',
            usersmoskit: Array,
            usersassociados: Array,
            etapatemplateassociados: Array,
            pipelines: Array,
            etapas: Array,
            templates: Array,
            polichatwebhookkey: 0
        }
    },
    methods: {
        obterUsuarioMoskitPorUsername(){

            let load = this.$loading.show()

            MoskitStore.listusersbyusername(this.username).then(result => {
                
                this.usersmoskit = result.data
            }).catch(error => {
                console.log(error)
                this.$toast.error(error)
            }).finally(()=>{
                load.hide()
            })
        },
        associarusuarioentreplataformas(){

            let load = this.$loading.show()

            var payload = {
                "idMoskit": this.idmoskit,
                "idPolichat": this.idpolichat,
            }

            IntegracaoStore.associarusuariosentreplataformas(JSON.stringify(payload)).then(result =>{
                this.$toast.success("Associação feita!")
            }).catch(error => {
                console.log(error)
                this.$toast.error(error)
            }).finally(()=>{
                load.hide()
                this.idpolichat = ''
                this.idmoskit = ''
                this.username = ''
                this.usersmoskit = []
            })
        },
        listarAssociados(){

            let load = this.$loading.show()

            IntegracaoStore.listarassociacaousuarioentreplataformas().then(result => {

                this.usersassociados = result.data
            }).catch(error => {
                console.log(error)
                this.$toast.error(error)
            }).finally(()=>{
                load.hide()
            })
        },
        dessassociarUsuarios(){
            let load = this.$loading.show()

            var payload = this.radioselected

            IntegracaoStore.desassociarusuario(payload).then(() =>{
                this.$toast.success("Desassociação feita!")
            }).catch(error => {
                console.log(error)
                this.$toast.error(error)
            }).finally(()=>{
                load.hide()
                this.idpolichat = ''
                this.usersassociados = []
            })
        },
        obterpipelines(){
            
            let load = this.$loading.show()

            MoskitStore.obterpipelines(this.qtd, this.start).then(result =>{
                
                this.pipelines = result.data

            }).catch(error => {
                console.log(error)
                this.$toast.error(error)
            }).finally(()=>{
                load.hide()
            })
        },
        nextpipelines(){

            let qtd = 10
            let start = this.start + qtd
            
            let load = this.$loading.show()

            MoskitStore.obterpipelines(qtd, start).then(result =>{
                this.pipelines = result.data
            }).catch(error => {
                console.log(error)
                this.$toast.error(error)
            }).finally(()=>{
                load.hide()
            })
        },
        previouspipelines(){

            if(this.start >= 0){
                let qtd = 10
                let start = this.start - qtd
            
                let load = this.$loading.show()

                MoskitStore.obterpipelines(qtd, start).then(result =>{
                    this.pipelines = result.data
                }).catch(error => {
                    console.log(error)
                    this.$toast.error(error)
                }).finally(()=>{
                    load.hide()
                })
            }
        },
        gravarfunil(){
            
            let load = this.$loading.show()

            IntegracaoStore.gravarfunis(JSON.stringify(this.pipelines)).then(() =>{
                this.$toast.success("Sucesso")
            }).catch(error => {
                console.log(error)
                this.$toast.error(error)
            }).finally(()=>{
                load.hide()
            })
        },
        modalassociaretapastemplates(){
            
            this.obterEtapasMoskit()
            this.obterTemplatesPolichat()
            
        },
        obterEtapasMoskit(){
            let load = this.$loading.show()
            MoskitStore.obteretapas(10000, 0).then(result =>{
                this.etapas = result.data
                load.hide()
            }).catch(error => {
                console.log(error)
                this.$toast.error(error)
                load.hide()
            })

        },
        obterTemplatesPolichat(){
            PolichatStore.obtertemplates().then(result =>{
                this.templates = result.data
            }).catch(error => {
                console.log(error)
                this.$toast.error(error)
            })
        },
        associaretapatemplate(){

            let load = this.$loading.show()

            var payload = {
                "idStagioMoskit": this.etapaSelected.id,
                "nomeEtapaMoskit": this.etapaSelected.nome,
                "idTemplatePolichat": this.templateSelected.id,
                "nomeTemplatePolichat": this.templateSelected.nome
            }

            IntegracaoStore.associaretapatemplate(JSON.stringify(payload)).then(result =>{
                this.$toast.success("Sucesso")
            }).catch(error => {
                console.log(error)
                this.$toast.error(error)
            }).finally(() => {
                load.hide()
            })
        },
        listarEtapaTemplateAssociados(){
            let load = this.$loading.show()

            IntegracaoStore.listarassociadosetapatemplate().then(result => {
                this.etapatemplateassociados = result.data
            }).catch(error => {
                console.log(error)
                this.$toast.error(error)
            }).finally(()=>{
                load.hide()
            })
        },
        desassociaretapatemplate(){
            let load = this.$loading.show()
            
            var payload = this.radioetapatemplate

            IntegracaoStore.desassociaretapatemplate(payload).then(() =>{
                this.$toast.success("Desassociação feita!")
            }).catch(error => {
                console.log(error)
                this.$toast.error(error)
            }).finally(()=>{
                load.hide()
                this.etapatemplateassociados = []
            })
        },
        gerarWebHookPolichat(){
            
            let load = this.$loading.show()

            var payload = {
                "idTemplatePolichat": this.templateSelected.id,
                "nomeTemplatePolichat": this.templateSelected.nome,
            }

            IntegracaoStore.gerarWebhookPolichat(JSON.stringify(payload)).then(() =>{
                this.$toast.success("Sucesso")
                
                this.polichatwebhookkey += 1
            }).catch(error => {
                console.log(error)
                this.$toast.error(error)
            }).finally(()=>{
                load.hide()
            })
        }
    }
}
</script>

<style>

</style>