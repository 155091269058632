<template>
   <!-- Form-->
   <div class="content-form d-flex justify-content-center">
      <div class="form">
         <div class="form-toggle"></div>
         <div class="form-panel one">
            <div class="form-header">
               <div class="row">
                  <div class="col-6">
                     <h1>Faça o Login</h1>
                  </div>
               </div>
            </div>
            <div class="form-content">
               <form @submit.stop.prevent="submit">
                  <div class="form-group">
                     <label for="username">E-mail</label>
                     <input v-model="email"
                      type="email" id="username" name="username" required="required"/>
                  </div>
                  <div class="form-group">
                     <label for="password">Senha</label>
                     <input v-model="password"
                      type="password" id="password" name="password" required="required"/>
                  </div>
                  <div class="form-group">
                     <button type="submit">Entrar</button>
                  </div>
               </form>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import Strings from '../common/strings'
import Cookie from 'js-cookie'
import IntegracaoStore from '../store/IntegracaoStore'

   export default {
       setup() {
       },
       data(){
           return {
               email: '',
               password: '',
           }
       },
       methods: {
          submit(){
            let load = this.$loading.show()

            IntegracaoStore.login(this.email, this.password).then(result => {
                this.$toast.success('Seja bem vindo (a)!')
                Cookie.set(Strings.cookie_token, result.data)
                this.$router.push('/')
            }).catch(error => {
                console.log(error)
                this.$toast.error(error)
            }).finally(()=>{
                load.hide()
            })
            
          }
       }
   }
</script>
<style scoped>
   .overlay, .form-panel.one:before {
   position: absolute;
   top: 0;
   left: 0;
   display: none;
   background: rgba(0, 0, 0, 0.8);
   width: 100%;
   height: 100%;
   }
   .content-form{
   width: 100%;
   height: 100vh;
   }
   .form {
   z-index: 15;
   position: relative;
   background: #FFFFFF;
   width: 600px;
   height: 380px;
   border-radius: 4px;
   box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
   box-sizing: border-box;
   overflow: hidden;
   margin: 150px auto 10px;
   }
   .form-toggle {
   z-index: 10;
   position: absolute;
   top: 60px;
   right: 60px;
   background: #FFFFFF;
   width: 60px;
   height: 60px;
   border-radius: 100%;
   transform-origin: center;
   transform: translate(0, -25%) scale(0);
   opacity: 0;
   cursor: pointer;
   transition: all 0.3s ease;
   }
   .form-toggle:before, .form-toggle:after {
   content: "";
   display: block;
   position: absolute;
   top: 50%;
   left: 50%;
   width: 30px;
   height: 4px;
   background: #4285F4;
   transform: translate(-50%, -50%);
   }
   .form-toggle:before {
   transform: translate(-50%, -50%) rotate(45deg);
   }
   .form-toggle:after {
   transform: translate(-50%, -50%) rotate(-45deg);
   }
   .form-toggle.visible {
   transform: translate(0, -25%) scale(1);
   opacity: 1;
   }
   .form-group {
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   margin: 0 0 20px;
   }
   .form-group:last-child {
   margin: 0;
   }
   .form-group label {
   display: block;
   margin: 0 0 10px;
   color: rgba(0, 0, 0, 0.6);
   font-size: 12px;
   font-weight: 500;
   line-height: 1;
   text-transform: uppercase;
   letter-spacing: 0.2em;
   }
   .form-group input {
   outline: none;
   display: block;
   background: rgba(0, 0, 0, 0.1);
   width: 100%;
   border: 0;
   border-radius: 4px;
   box-sizing: border-box;
   padding: 12px 20px;
   color: rgba(0, 0, 0, 0.6);
   font-family: inherit;
   font-size: inherit;
   font-weight: 500;
   line-height: inherit;
   transition: 0.3s ease;
   }
   .form-group input:focus {
   color: rgba(0, 0, 0, 0.8);
   }
   .form-group button {
   outline: none;
   background: #4285F4;
   width: 100%;
   border: 0;
   border-radius: 4px;
   padding: 12px 20px;
   color: #FFFFFF;
   font-family: inherit;
   font-size: inherit;
   font-weight: 500;
   line-height: inherit;
   text-transform: uppercase;
   cursor: pointer;
   }
   .form-group .form-remember {
   font-size: 12px;
   font-weight: 400;
   letter-spacing: 0;
   text-transform: none;
   }
   .form-group .form-remember input[type=checkbox] {
   display: inline-block;
   width: auto;
   margin: 0 10px 0 0;
   }
   .form-group .form-recovery {
   color: #4285F4;
   font-size: 12px;
   text-decoration: none;
   }
   .form-panel {
   padding: 10px calc(5% + 60px) 60px 60px;
   box-sizing: border-box;
   }
   .form-panel.one:before {
   content: "";
   display: block;
   opacity: 0;
   visibility: hidden;
   transition: 0.3s ease;
   }
   .form-panel.one.hidden:before {
   display: block;
   opacity: 1;
   visibility: visible;
   }
   .form-header {
   margin: 0 0 40px;
   }
   .form-header h1 {
   padding: 4px 0;
   color: #4285F4;
   font-size: 24px;
   font-weight: 700;
   text-transform: uppercase;
   }
   .cp-fab {
   background: #FFFFFF !important;
   color: #4285F4 !important;
   }
</style>