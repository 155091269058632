import { http } from '../services/http'
import Cookie from 'js-cookie'
import Strings from '../common/strings'

export default{
    listusersbyusername(payload){
        let option = {
            headers: {
              'Authorization': `Bearer ${Cookie.get(Strings.cookie_token)}`,
            }
        }
        return http.post('/Moskit/usuarios?username='.concat(payload), null, option)
    },
    associarusuariosentreplataformas(payload){
        let option = {
            headers: {
              'Authorization': `Bearer ${Cookie.get(Strings.cookie_token)}`,
            }
        }
        return http.post('/Integracao/gravar/usuariosEntrePlataformas', payload, option)
    },
    obterpipelines(qtd, start){
        let option = {
            headers: {
              'Authorization': `Bearer ${Cookie.get(Strings.cookie_token)}`,
            }
        }
        return http.post('/Moskit/pipelines?qtd='.concat(qtd).concat("&start=".concat(start)), null, option)
    },
    obteretapas(qtd, start){
        let option = {
            headers: {
              'Authorization': `Bearer ${Cookie.get(Strings.cookie_token)}`,
            }
        }
        return http.post('/Moskit/stages?qtd='.concat(qtd).concat("&start=".concat(start)), null, option)
    }
}