<template>
<div>
  <HomeComponent />
  <NavbarComponent />
</div>
</template>

<script>
import HomeComponent from './../components/HomeComponent.vue'
import NavbarComponent from './../components/NavbarComponent.vue'

export default {
  components: {
    HomeComponent,
    NavbarComponent
  }
}
</script>

<style scoped>
</style>