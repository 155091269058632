<template>
    <NotFoundComponent />
</template>
<script>
import NotFoundComponent from './../components/NotFoundComponent.vue'
export default {
  components: {
    NotFoundComponent
  }
}
</script>
<style scoped>
</style>