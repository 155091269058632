import Cookie from 'js-cookie'
import Strings from '../common/strings'
import { http } from './http'

export default{
    auth(to, from, next){
        
        const token = Cookie.get(Strings.cookie_token)
        
        if(token != 'undefined'){
            http.post('/Integracao/validateToken', JSON.stringify(token)).then(() => {
                next()
            }).catch(function (){
                Cookie.remove(Strings.cookie_token)
                next('/login')
            })
        }else{
            next('/login')
        }
    },
}