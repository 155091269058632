<template>
  <div>
    <table class="table align-middle mb-0 bg-white">
      <thead class="bg-light">
        <tr>
          <th>Nº</th>
          <!-- <th>Id Moskit</th> -->
          <th>Etapa</th>
          <th>Webhook</th>
          <th>Criado em</th>
          <th>Atualizado em</th>
          <th style="text-align: center;">Opções</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(polichatWebhook, index) in this.polichatWebhook" v-bind:key="index" :value="{id: polichatWebhook.idTemplatePolichat, nome: polichatWebhook.nomeTemplatePolichat}">
          <td>
            <div class="d-flex align-items-center">
              <p class="fw-bold mb-1">{{index + 1}}</p>
            </div>
          </td>
          <!-- <td>
            <div class="d-flex align-items-center">
              <p class="fw-bold mb-1">{{etapa.id}}</p>
            </div>
          </td> -->
          <td>
            <p class="fw-normal mb-1">{{polichatWebhook.nomeTemplatePolichat}}</p>
          </td>
          <td>
            <span class="badge badge-warning rounded-pill d-inline">{{ apiUrl + "/webhook/" + polichatWebhook.idWebhook }}</span>
          </td>
          <td>
            <p class="badge badge-primary rounded-pill d-inline">
              {{this.dateTime(polichatWebhook.criado_Em)}}
            </p>
          </td>
          <td>
            <p class="badge badge-success rounded-pill d-inline">
              {{this.dateTime(polichatWebhook.atualizado_Em)}}
            </p>
          </td>
          <td>
              <div class="row d-flex justify-content-center">
                <div class="col-sm-8 m-1">
                  <button type="button" @click="this.removerpolichatwebhook(polichatWebhook.idTemplatePolichat)" class="btn btn-danger btn-rounded btn-sm fw-bold" data-mdb-ripple-color="dark"><i class="fas fa-trash"></i></button>
                </div>
              </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import IntegracaoStore from '../store/IntegracaoStore.js'
import Strings from '../common/strings'
import moment from 'moment'

export default{
  created() {
    this.obterPolichatWebhook()
  },
  data(){
        return {
            apiUrl: Strings.apiUrl,
            polichatWebhook: Array
        }
    },
    methods: {
      dateTime(value) {
        return moment(value).format('DD/MM/YYYY hh:mm:ss');
      },
      obterPolichatWebhook(){

        this.polichatWebhook = Array()
        let load = this.$loading.show()

        IntegracaoStore.listarpolichatwebhook().then(result =>{
                this.polichatWebhook = result.data
            }).catch(error => {
                console.log(error)
                this.$toast.error(error)
            }).finally(()=>{
              load.hide()
            })
        },
        removerpolichatwebhook(id){
          let load = this.$loading.show()

            IntegracaoStore.removerPolichatWebhook(id).then(() =>{
                this.$toast.success("Removido")

                this.obterPolichatWebhook()
            }).catch(error => {
                console.log(error)
                this.$toast.error(error)
            }).finally(()=>{
                load.hide()
          })
        }
    }
}
</script>
