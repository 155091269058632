<template>
    <div class="text-center">
        <div class="container">
            <div class="error-content">
                <h2>404</h2>
                <p>Ooops! Algo deu errado.</p>
                <router-link v-bind:to="'/'">Voltar para o inicio</router-link >
            </div>
        </div>
    </div>
</template>
<script></script>
<style scoped></style>