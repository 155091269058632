<template>
  <!-- Navbar -->
<nav class="navbar navbar-expand-lg fixed-top bg-light navbar-light">
  <div class="container">
    <a class="navbar-brand" href="#"
      ><img
        id="unifasam-logo"
        src="./../assets/logo.png"
        alt="MDB Logo"
        draggable="false"
        height="40"
    /></a>
    <button
      class="navbar-toggler"
      type="button"
      data-mdb-toggle="collapse"
      data-mdb-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <i class="fas fa-bars"></i>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ms-auto align-items-center">
        <li class="nav-item ms-3">
          <a @click="sair()" class="btn btn-black btn-rounded" href="#!">Sair</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
<!-- Navbar -->
</template>
<script>
import Strings from '../common/strings.js'
import Cookie from 'js-cookie'

export default {
  data(){
  },
  methods:{
        sair(){

            let load = this.$loading.show()
            Cookie.remove(Strings.cookie_token)
            this.$router.push('/login')
            load.hide()
        }
  }
}
</script>

<style>
.navbar-light .navbar-nav .nav-link {
  color: #000;
}
</style>