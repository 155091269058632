<template>
<div class="content">
    <LoginComponent />
</div>
</template>

<script>
import LoginComponent from './../components/LoginComponent.vue'
export default {
  components: {
      LoginComponent,
  },
  methods: {
    login(){
      
    }
  },
}
</script>

<style scoped>
.content{
  background: -webkit-linear-gradient(right, #042a5a, #141416);
}
</style>