import { http } from '../services/http'
import Cookie from 'js-cookie'
import Strings from '../common/strings'

export default{
    login(username, senha){
        return http.post('/Integracao/login?username='.concat(username).concat("&senha=".concat(senha)), null)
    },
    associarusuariosentreplataformas(payload){
        let option = {
            headers: {
              'Authorization': `Bearer ${Cookie.get(Strings.cookie_token)}`,
            }
        }

        return http.post('/Integracao/gravar/usuariosEntrePlataformas', payload, option)
    },
    desassociarusuario(payload){
        let option = {
            headers: {
              'Authorization': `Bearer ${Cookie.get(Strings.cookie_token)}`,
            }
        }

        return http.delete('/Integracao/remover/usuariosEntrePlataformas/'.concat(payload), option)
    },
    listarassociacaousuarioentreplataformas(){
        let option = {
            headers: {
              'Authorization': `Bearer ${Cookie.get(Strings.cookie_token)}`,
            }
        }
        return http.post('/Integracao/lista/usuariosEntrePlataformas', null, option)
    },
    gravarfunis(payload){
        let option = {
            headers: {
              'Authorization': `Bearer ${Cookie.get(Strings.cookie_token)}`,
            }
        }
        return http.post('/Integracao/gravar/funisMoskit', payload, option)
    },
    associaretapatemplate(payload){
        let option = {
            headers: {
              'Authorization': `Bearer ${Cookie.get(Strings.cookie_token)}`,
            }
        }
        return http.post('/Integracao/gravar/estagioTemplate', payload, option)
    },
    desassociaretapatemplate(payload){
        let option = {
            headers: {
              'Authorization': `Bearer ${Cookie.get(Strings.cookie_token)}`,
            }
        }
        return http.delete('/Integracao/remover/estagioTemplate/'.concat(payload), option)
    },
    listarassociadosetapatemplate(){
        let option = {
            headers: {
              'Authorization': `Bearer ${Cookie.get(Strings.cookie_token)}`,
            }
        }
        return http.post('/Integracao/lista/etapatemplates', null, option)
    },
    listarpolichatwebhook(){
        let option = {
            headers: {
              'Authorization': `Bearer ${Cookie.get(Strings.cookie_token)}`,
            }
        }
        return http.post('/Integracao/listar/polichat/webhook', null, option)
    },
    gerarWebhookPolichat(payload){
        let option = {
            headers: {
              'Authorization': `Bearer ${Cookie.get(Strings.cookie_token)}`,
            }
        }
        return http.post('/Integracao/gravar/polichat/webhook', payload, option)
    },
    removerPolichatWebhook(payload){
        let option = {
            headers: {
              'Authorization': `Bearer ${Cookie.get(Strings.cookie_token)}`,
            }
        }

        return http.delete('/Integracao/remover/polichat/webhook/'.concat(payload), option)
    },
}