import { createRouter } from 'vue-router'

import Home from '../views/Home.vue'
import Guard from '../services/middleware'
import Login from '../views/Login.vue'
import Error404 from '../views/Error404.vue'

const routes = [
  { path: '/', component: Home, beforeEnter: Guard.auth},
  { path: '/login', component: Login},
  { path: '/:pathMatch(.*)*', component: Error404}
]

export default function (history) {
  return createRouter({
    history,
    routes
  })
}